<template>
    <section class="detail-setup-quiz text-white">
        <div class="container-fluid inner-setup-quiz text-left">
            <div class="row">
                <div class="col-3">
                    <div class="card mb-3 mt-5">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label type="button" @click="$bvModal.show('upload-image')">
                                        <div class="d-flex img-wrap">
                                            <img v-if="images_cover != '-' && images_cover != '' &&  images_cover != null && images_cover != undefined && images_cover != 'undefined'" :src="images_cover"  alt="" class="img-fluid">
                                            <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-fluid"/>
                                            <div class="img__description_layer">
                                                <p class="img__description">Click to change image</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <b-alert variant="danger" :show="$store.state.validationTemplate == true" fade dismissible>
                                    Nama Quiz tersebut sudah pernah kamu buat !
                                </b-alert>
                                <label for="txt-nama-quiz">Quiz Name*</label>
                                <input type="text" class="form-control" :class="$store.state.validationTemplate == true ? 'is-invalid' : ''" id="txt-nama-quiz" v-model="quiz_name">
                            </div>
                            <div class="form-group">
                                <label for="txt-deskripsi-quiz">Quiz Description</label>
                                <textarea v-model="description" class="form-control" id="txt-deskripsi-quiz" rows="4"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="dd-subject">Subjek*</label>
                                <select name="subject" id="dd-subject" class="form-control" v-model="category_id" @change="changeLearningCategories()">
                                    <option v-if="categories.length == 0" disabled>Tidak ada data</option>
                                    <option 
                                        v-for="(value, index) in categories" 
                                        :key="index" 
                                        :value="value._id"
                                    >{{value.category_name}}</option>
                                </select>
                            </div>
                            <!-- <div class="form-group mb-1">
                                <label for="">Tentukan grade level :</label>
                                <div class="w-100">
                                    <button v-for="(value, index) in list_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1" :class="grade == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setGrade(value._id, index)"><small :class="grade == value._id ? 'text-white ': 'text-dark'">{{value.grade}}</small></button>
                                </div>
                            </div>
                            <template v-if="list_grade.length > 0">
                                <div class="form-group" v-if="list_grade[indexGrade].sub_grade.length > 0">
                                    <label for="">Sub grade :</label>
                                    <div class="w-100">
                                        <button v-for="(value, index) in list_grade[indexGrade].sub_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1" :class="sub_grade == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setSubGrade(value._id)"><small :class="sub_grade == value._id ? 'text-white ': 'text-dark'">{{value.sub_grade}}</small></button>
                                    </div>
                                </div>
                            </template> -->
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="head-card">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="question-total d-flex align-items-center">
                                <img src="@/assets/images/icons/ic_document.svg" alt="question" class="border-none">
                                <h3 class="font-weight-normal ml-2 mr-auto">{{ this.listQuestion.length }} Pertanyaan</h3>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-pg" v-for="(data, index) in listQuestion" :key="index" v-katex:auto>
                        <div class="card" >
                            <div class="card-body py-3">
                                <div class="container-fluid">
                                    <div class="d-flex align-items-center mb-3">
                                        <div class="col-md-6">
                                            <h5 class="font-weight-normal">{{index + 1}}. {{ data.question_type == "Multiple Choice" || data.question_type == null || data.question_type == "" ? titleMultipleChoices : data.question_type == "True or False" ? titleTrueFalse : data.question_type == "Fill in the Blank" ? titleFillInTheBlank : titleMenjodohkan}} </h5>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex action-question">
                                                <select name="" v-model="data.point" id="poin" class="form-control mr-3" disabled>
                                                    <option :value="5">5 Points</option>
                                                    <option :value="10">10 Points</option>
                                                    <option :value="15">15 Points</option>
                                                    <option :value="20">20 Points</option>
                                                </select>
                                                <select name="" v-model="data.duration" id="time" class="form-control mr-3" disabled>
                                                    <option :value="10">10 Seconds</option>
                                                    <option :value="30">30 Seconds</option>
                                                </select>
                                                <button style="cursor : pointer" type="button" @click="tambahPertanyaan(index)" class="btn btn-action mr-3 d-flex align-items-center"><img src="@/assets/images/icons/Edit.svg" alt="" class="mr-1 invert"><span class="text-white">Edit</span></button>
                                                <button type="button" class="btn btn-action" @click="deleteItem(index)"><img src="@/assets/images/icons/ic_delete_2.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-3">
                                    <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="image-url-style mr-2"/>
                                        <p class="text-break mb-0"><span class="text-break ml-1 " v-html="data.question"></span></p>
                                    </div>
                                    <div>
                                        <div>
                                            <p class="text-secondary-2 mb-0">Answers</p>
                                            <hr class="mt-1">
                                        </div>
                                        <div class="answer container-fluid w-100">
                                            <div class="row">
                                                <div v-if="data.question_type == 'Multiple Choice' || data.question_type == 'Fill in the Blank' || data.question_type == null || data.question_type == ''"  class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">A. <span class="text-break ml-1 " v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">B. <span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_3 != null && data.option_3 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '3' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">C. <span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_4 != null && data.option_4 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '4' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex">D. <span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                        <!-- <vs-radio :color="data.correct_answer == '1' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_1">A. {{data.option_1}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '2' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_2">B. {{data.option_2}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '3' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_3">C. {{data.option_3}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '4' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_4">D. {{data.option_4}}</vs-radio> -->
                                                    </div>
                                                </div>
                                                <div v-else-if="data.question_type == 'True or False'" class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="data.question_type == 'Match'" class="col-12 container-fluid text-left">
                                                    <table style="width:50%" border>
                                                        <tr>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_1 == null || data.img_option_1 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                                    <img v-else :src="data.img_option_1" alt="img1" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_2 == null || data.img_option_2 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                                    <img v-else :src="data.img_option_2" alt="img2" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_3 == null || data.img_option_3 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                                    <img v-else :src="data.img_option_3" alt="img3" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="answer-def"></span>
                                                                    </div>
                                                                    <p v-if="data.img_option_4 == null || data.img_option_4 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                                    <img v-else :src="data.img_option_4" alt="img4" class="img-option ml-2">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_1"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_2"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_3"></span></p>
                                                                </div>
                                                            </td>
                                                            <td style="width:20%">
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <span class="correct-active"></span>
                                                                    </div>
                                                                    <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_4"></span></p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="alert-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('alert-quiz')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Kamu yakin ingin</h5>
                    <h5 class="font-weight-bold">menghapus konten ini?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary btn-purple-nb" @click="$bvModal.hide('alert-quiz')"><p class="mx-2 mb-0">Iya</p></button>
                </div>
            </div>
        </b-modal>

        <!-- MODAL UPLOAD IMG CVR -->
        <b-modal id="upload-image" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <!-- <div class="my-4 mx-4">
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                </div>
            </div> -->
            <div class="position-relative mt-2">
                <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                <vs-tabs :color="colorx">
                    <vs-tab label="Gallery">
                         <div class="con-tab-ejemplo gallery mt-2">
                            <label for="search">
                                <input type="text" v-model="filterThumb" class="search-bar" placeholder="Find thumbnail">
                            </label>
                            <div class="container-fluid overflow-auto container-thumbnail mt-3">
                                <div class="row">
                                    <div v-for="(data, index) in searchThumb" :key="index" class="col-md-3 mb-3 px-0">
                                        <div class="mr-2 ml-2">
                                            <button class="btn-thumbnail" @click="fromThumbnail(data.file, data.name)">
                                                <img :src="data.file" :alt="data.name" class="img-fluid thumbnail-img"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Upload">
                         <div class="con-tab-ejemplo" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                            <b-alert variant="danger" :show="showAlertImg" fade dismissible>
                                {{ responseValidateImg }}   
                            </b-alert>
                            <label @drop="dragImage($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                                <div v-if="images_cover_before != '-' && images_cover_before != '' &&  images_cover_before != null && images_cover_before != undefined" class="pt-3">
                                    <img :src="images_cover_before"  alt="" class="img-form">
                                    <small class="my-auto ml-2">{{ typeof imageFileBefore === "string" ? 'cover_image.jpg' : imagenameBefore }}</small>
                                </div>
                                <div v-else>
                                    <img src="@/assets/images/logo/paper_impor.svg" alt="">
                                    <h4 class="font-weight-normal mt-2 text-blue">Choose image</h4>
                                    <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                                </div>
                                <input type="file" style="display: none" @change="imageUpload($event)"/>
                            </label>
                        </div>
                    </vs-tab>
                </vs-tabs>
                <hr>
                <div class="float-right">
                    <button class="btn btn-secondary btn-md btn-grey text-dark mr-2" @click="cancelImage"><p class="mb-0 mx-2">Cancel</p></button>
                    <button class="btn btn-warning btn-md text-white" @click="saveImage"><p class="mb-0 mx-2">Use</p></button>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>
import $ from "jquery"
import {getQuizById} from '@/services/quiz/quiz.service'
import {getCategoriesAll} from '@/services/quiz-categories/quiz_categories.service'
// import {getMasterGradeAll} from '@/services/master-grades/master-grades.service'
import {getLearningCategories} from '@/services/learning-categories/learning-categories.service'
import {getQuizCreatorById} from '@/services/quiz-creator/quiz-creator.service'
import {getMasterThumbnails} from '@/services/master-thumbnails/master-thumbnails.service'
import { getGrades } from '@/services/generative-ai/generative-ai.service'

export default {
    layouts: {
        default: '~/views/layouts/empty.vue',
    },
    data(){
        return {
            dataQuiz : {
                _id: null,
                category_id: null,
                learning_category_id: null,
                grade_id: null,
                quiz_name: null,
                author:null,
                images_cover:null,
                categories_quiz: {
                    category_name: null
                } 
            },
            indexGrade: 0,
            grade: null,
            sub_grade: null,
            list_grade: [],
            categories: [],
            dataQuestion:[],
            learnings:[],
            listQuestion: [],
            formQuizCreator :{
                quiz_name : '',
                is_use_template : true,
                category_id : '',
                quiz_template_id : this.$route.query.key,
                image_cover : '',
            },
            imageFile : "",
            responseValidateImg: "",
            filterThumb: null,
            showAlertImg: false,
            imagename: "",
            imagenameBefore: "",
            imageFileBefore: "",
            images_cover_before: "",
            colorx: 'rgb(188, 59, 242)',
            quiz_name: null,
            description: null,
            category_id: null,
            images_cover: null,
            thumbnail: [ "reproduksi.svg","metabolisme.svg", "tkj.svg", "sosiologi.svg", "geografi.svg", "Digital Marketing.svg",  "Bahasa Mandarin.svg", "Bahasa Jepang.svg" ],
            masterThumbnails : [],
            titleMultipleChoices : "Pilihan Ganda",
            titleTrueFalse : "Benar atau Salah",
            titleFillInTheBlank : "Short Answers",
            titleMenjodohkan : "Menjodohkan",
        }
    },

    watch: {
        quiz_name(newQuizName) {
            localStorage.quiz_name_temp = newQuizName;
        },
        description(newQuizDescription) {
            localStorage.description_temp = newQuizDescription;
        },
        images_cover(newImagesCover) {
            localStorage.images_cover_temp = newImagesCover;
        },
        category_id(newCategoryID) {
            localStorage.category_id_temp = newCategoryID;
        },
    },
    mounted(){
        const id = this.$route.query.key
        if(localStorage.quiz_name_temp || localStorage.description_temp || localStorage.images_cover_temp || localStorage.category_id_temp || localStorage.grade_temp || localStorage.sub_grade_temp  || localStorage.index_grade_temp){
            if(localStorage.quiz_name_temp){
                this.quiz_name = localStorage.quiz_name_temp
            }
            if(localStorage.description_temp){
                this.description = localStorage.description_temp
            }
            if(localStorage.images_cover_temp){
                this.images_cover = localStorage.images_cover_temp
            }
            if(localStorage.category_id_temp){
                this.category_id = localStorage.category_id_temp
            }
            if(localStorage.grade_temp){
                this.grade = localStorage.grade_temp
            }
            if(localStorage.sub_grade_temp){
                this.sub_grade = localStorage.sub_grade_temp
            }
            if(localStorage.index_grade_temp){
                this.indexGrade = localStorage.index_grade_temp
            }
        } else {
            this.getDetailQuiz(id)
        }
        this.getMasterGrade()
        this.getCategories()
        this.setSideBar()
        this.getThumbnail()
        this.getLearningCategories(this.$store.state.templateData.category_id)
        let dataQuestion = localStorage.getItem("listQuestion");
        if(this.$store.state.canceledTempalte == true) {
            this.listQuestion = JSON.parse(localStorage.backupQuestionTemplate);
            localStorage.setItem("listQuestion",JSON.stringify(this.listQuestion));
            this.$store.state.canceledTempalte = false
        } else {
            this.listQuestion = JSON.parse(dataQuestion);
        }

        $("section.detail-setup-quiz").parent().addClass('mx-2');
    },
    computed: {
        searchThumb() {
            if (this.filterThumb) {
                return this.masterThumbnails.filter((item) => {
                return this.filterThumb
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.masterThumbnails;
            }
        },
    },
    methods: {
        getDetailQuiz(id){
            const author = this.$route.query.author
            if(author == '0') {
                getQuizById(id)
                .then(response => {
                    if(response.status && response.status == true){    
                        this.dataQuiz = response.data[0]
                        localStorage.quiz_name_temp = this.dataQuiz.quiz_name
                        localStorage.description_temp = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
                        localStorage.images_cover_temp = this.dataQuiz.images_cover
                        localStorage.category_id_temp = this.dataQuiz.category_id
    
                        if(localStorage.quiz_name_temp){
                            this.quiz_name = localStorage.quiz_name_temp
                        }
                        if(localStorage.description_temp){
                            this.description = localStorage.description_temp
                        }
                        if(localStorage.images_cover_temp){
                            this.images_cover = localStorage.images_cover_temp
                        }
                        if(localStorage.category_id_temp){
                            this.category_id = localStorage.category_id_temp
                        }
                    }
                    this.getLearningCategories(this.category_id)
                }).catch(error => {
                    console.log(error)
                })
            } else {
                getQuizCreatorById(id)
                .then(response => {
                    if(response.status && response.status == true){    
                        this.dataQuiz = response.data[0]
                        localStorage.quiz_name_temp = this.dataQuiz.quiz_name
                        localStorage.description_temp = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
                        localStorage.images_cover_temp = this.dataQuiz.images_cover
                        localStorage.category_id_temp = this.dataQuiz.category_id
    
                        if(localStorage.quiz_name_temp){
                            this.quiz_name = localStorage.quiz_name_temp
                        }
                        if(localStorage.description_temp){
                            this.description = localStorage.description_temp
                        }
                        if(localStorage.images_cover_temp){
                            this.images_cover = localStorage.images_cover_temp
                        }
                        if(localStorage.category_id_temp){
                            this.category_id = localStorage.category_id_temp
                        }
                    }
                    this.getLearningCategories(this.category_id)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        getCategories(){
            getCategoriesAll()
            .then(response => {
                if(response.status && response.status == true){    
                    this.categories = response.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getMasterGrade(){
            getGrades()
            .then(response => {
                if(response.status && response.status == true){    
                    this.list_grade = response.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getLearningCategories(id){
            getLearningCategories(id)
            .then(response => {
                if(response.status && response.status == true){    
                    this.learnings = response.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        changeLearningCategories(){
            this.learnings = []
            this.getLearningCategories(this.dataQuiz.category_id)
        },
        setSideBar(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        },
        publishQuiz(){
            this.$router.push({ name: 'Publish-Quiz' })
        },
        tambahPertanyaan(index){
            var id = this.$route.query.key
            localStorage.setItem('backupQuestionTemplate', JSON.stringify(this.listQuestion));
            this.$router.push({ name: 'Create-Question', query: {key: id, index : index+1} })
        },
        deleteItem(index){
            this.listQuestion.splice(index, 1)
            localStorage.setItem('listQuestion', JSON.stringify(this.listQuestion));
        },

        convertImage(file, form) {
            var vm = this;
            var reader = new FileReader();
            reader.onloadend = function () {
                switch (form) {
                    case 'images_cover':
                        // vm.$store.state.templateData.images_cover = reader.result
                        vm.images_cover_before = reader.result
                        break;
                    default:
                        break;
                }
            };
            reader.readAsDataURL(file);
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },

        imageUpload(e) {
            let size = e.target.files[0].size;

            var fileSizes = this.formatBytes(size);

            let type = e.target.files[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (fileSizes > 25000000) {
                    this.showAlertImg = true;
                    this.responseValidateImg = "Ukuran file melebihi 25MB";
                } else {
                    this.showAlertImg = false;
                    this.imagenameBefore = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                    this.imageFileBefore = e.target.files[0];
                    // this.$store.state.templateData.images_cover = URL.createObjectURL(this.imageFile)
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },

        getThumbnail(){
            getMasterThumbnails()
            .then((response) =>{
                if(response.status && response.status == true){
                    this.masterThumbnails = response.data
                }
            })
        },

        dragImage(e){
            const file = [...e.dataTransfer.files];
            
            let size = file[0].size;

            var fileSizes = this.formatBytes(size);

            let type = file[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (fileSizes > 25000000) {
                    this.showAlertImg = true;
                    this.responseValidateImg = "Ukuran file melebihi 25MB";
                } else {
                    this.showAlertImg = false;
                    this.imagenameBefore = file[0].name
                        ? file[0].name
                        : "Undefined";
                    this.imageFileBefore = file[0];
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },

        saveImage(){
            this.imagename = this.imagenameBefore
            this.imageFile = this.imageFileBefore
            // this.images_cover = this.images_cover_before
            this.images_cover = this.images_cover_before

            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.$bvModal.hide('upload-image')
        },

        cancelImage(){
            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.showAlertImg = false;
            this.$bvModal.hide('upload-image')
        },

        fromThumbnail(image, name){
            this.imagenameBefore = name
            this.imageFileBefore = image
            this.images_cover_before = image
        },

        setGrade(value, index) {
            this.indexGrade = index
            this.grade = value
            if(this.list_grade[index].sub_grade.length > 0) {
                this.sub_grade = this.list_grade[index].sub_grade[0]._id
            }else{
                this.sub_grade = ''
            }
            localStorage.setItem("index_grade_temp", index)
            localStorage.setItem("grade_temp", value)
        },

        setSubGrade(value){
            this.sub_grade = value
            localStorage.setItem("sub_grade_temp", value)
        }
    }
}
</script>

<style scoped>
    table, th, td {
        border: 0;
    }

    .inner-setup-quiz {
        background-color: black;
    }

    tr,td{
        padding-top: 1rem;
    }
    .label {
        font-size: 1.15rem;
    }

    .invert{
        filter: invert(100%);
    }

    .form-control{
        background-color: #222222;
        color: white;
        border-color: #383838;
    }

    .action-question .form-control, .btn-action{
        background-color: #8C8C8C;
    }

    hr{
        background : #8C8C8C;
    }

    .text-secondary-2{
        color: #8C8C8C
    }

    .image-url-style{
        width: 6rem;
        height: 6rem;
    }

    .answer-def{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #D9D9D9;
    }
    
    .img-option{
        height: 3.5rem;
        width: 3.75rem;
        border-radius: 6px;
    }

    .img-form {
        width: 70px;
        height: 70px;
    }
    
    .text-none{
        font-size: 14px;
        color: #636363;
    }

    .head-card {
        height: 3rem;
    }

    .row .col-3, .row .col-9 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container-image {
        position: relative;
        width: 25%;
    }
    .container-image:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .container-image img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .select-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        background-color: #E5E5E5;
        -moz-appearance:none !important;
        -webkit-appearance: none !important; 
        appearance: none !important;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .questions { 
        margin-bottom: 1rem;
    }

    .questions .card {
        border-radius: 10px;
    }

    .questions .card .card-body {
        padding-left: 2.25rem;
    }

    .logo-excel{
        width: 18px;
        height: 18px;
    }
    
    .btn-impor{
        border-radius: 25px;
    }

    .card {
        border-radius: 10px;
    }

    .correct-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #40BE45;
    }

    .incorrect-active{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #EB5757;
    }

    .answer-def{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #D9D9D9;
    }

    .search-bar {
        height: 3rem;
        width: 100%;
        border-radius: 25px;
        border: 1px solid #E5E5E5;
        padding-left: 48px !important;
        padding-right: 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
    }

    .gallery label {
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: 25px !important;
        z-index: 2;
    }

    .gallery label:before {
        content: "";
        position: absolute;
        left: 1rem;
        top: 10px;
        bottom: 0;
        width: 40px;
        background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
        background-size: 20px;
    }

    .thumbnail-img {
        border-radius: 10px 10px 10px 10px
    }

    .btn-thumbnail{
        background-color: transparent;
        border: none;
    }

    .btn-thumbnail:focus img {
        border: 3px solid #BC3BF2;
        border-radius: 10px;
    }

    .container-thumbnail{
        height: 28rem;
    }

    .container-thumbnail::-webkit-scrollbar {
        width: 6px;
    }

        /* Track */
    .container-thumbnail::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
        
        /* Handle */
    .container-thumbnail::-webkit-scrollbar-thumb {
        background: #B6B6B6; 
        border-radius: 4px;
    }

        /* Handle on hover */
    .container-thumbnail::-webkit-scrollbar-thumb:hover {
        background: #888; 
    }

    .close-image{
        right : 5px;
        z-index: 100;
    }

    .btn-grey{
        background-color: #C4C4C4 !important;
        border: 0px !important;
    }

    .btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .btn-impor-custom{
        background-color: #E5E5E5;
        border: 1px solid #D9D9D9;
        height: 14rem;
    }

    .img-wrap{
        position: relative;
    }

    .img-wrap img{
        border-radius: 10px;
    }

    .img__description_layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        /* transition effect. not necessary */
        transition: opacity .2s, visibility .2s;
    }

    .img-wrap:hover .img__description_layer {
        visibility: visible;
        opacity: 1;
    }

    .img__description {
        transition: .2s;
        transform: translateY(1em);
    }

    .img-wrap:hover .img__description {
        transform: translateY(0);
    }

    .btn-purple-pill {
        color: white;
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        height: 1.9rem;
        border-radius: 25px;
    }

    .btn-grey-pill {
        background-color: #E5E5E5;
        border: 1px solid #E5E5E5;
        height: 1.9rem;
        border-radius: 25px;
    }
</style>
